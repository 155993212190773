<template>
<div class="TheArticleAdd">
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <el-form-item label="文章名称" prop="articleTitle">
      <el-input v-model="ruleForm.articleTitle"></el-input>
    </el-form-item>
    <el-form-item label="文章标题" prop="articleAbstract">
      <el-input v-model="ruleForm.articleAbstract"></el-input>
    </el-form-item>
    <el-form-item label="文章作者" prop="articleAuthor">
      <el-input v-model="ruleForm.articleAuthor"></el-input>
    </el-form-item>
    <el-form-item label="分类" prop="articleCategory">
      <el-select  v-model="ruleForm.articleCategory" placeholder="请选择活动区域">
        <el-option v-for="categorys in category" :key="categorys.categoryId" :label="categorys.categoryName" :value="categorys.categoryId"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="是否上架" prop="delivery">
      <el-radio-group v-model="ruleForm.articleShelvesOr" size="medium">
        <el-radio border :label="0">上架</el-radio>
        <el-radio border label="1">下架</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="内容" prop="articleText">
      <v--article-text style="height: 100%" v-model="ruleForm.articleText"></v--article-text>
    </el-form-item>
    <el-form-item style="margin-top: 60px">
      <el-button type="primary" @click="submitForm('ruleForm')">立即创建</el-button>
      <el-button @click="resetForm('ruleForm')">重置</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import ArticleText from '@/components/ArticleText'
export default {
    name: "TheArticleAdd",
    components:{
      'v-ArticleText':ArticleText
      },
  data() {
    return {
      ruleForm: {
        articleTitle: '',
        articleAbstract: '',
        articleText: '',
        articleAuthor: '',
        articleCategory: '',
        articleShelvesOr: 0
      },
      category:[],
      rules: {
        articleTitle: [
          { required: true, message: '请输入文章标题', trigger: 'blur' },
          { min: 0, max: 50, message: '长度在 0 到 50个字符', trigger: 'blur' }
        ],
        articleCategory: [
          { required: true, message: '请选择文章状态', trigger: 'change' }
        ],
        articleAbstract:[
          { required: false, message: '请选择文章标题', trigger: 'change' }
        ],
        articleAuthor:[
          { required: false, message: '请输入文章作者', trigger: 'change' }
        ],
        articleText: [
          { required: true, message: '请填写文章内容', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post('https://www.realmmy.com/api/manage/articleAdd',this.ruleForm).then(function (resp) {
           alert(resp.data)
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  created() {
    const _this = this
    axios.post('https://www.realmmy.com/api/manage/category').then(function (resp) {
      _this.category = resp.data
    })
  }

}
</script>

<style scoped>

</style>
