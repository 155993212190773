<template>
<div class="music">
  123456
  <audio  controls :src="musicurl">1111 </audio>
</div>
</template>

<script>
export default {
  name: "music",
  data(){
    return{
      musicurl:"https://m801.music.126.net/20210213141009/79c1408779993502b03204f17bfae4e0/jdyyaac/obj/w5rDlsOJwrLDjj7CmsOj/7415028255/eec1/c791/6e74/b4037c27d1f9c8c4beed013e11b9bdc2.m4a",
    }
  }
}
</script>

<style scoped>

</style>