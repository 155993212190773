<template>
<div class="ArticleStatistical">
  <el-table
    :data="tableData"
    style="width: 100%"
    @expand-change="openFun"
  >
  <el-table-column  type="expand">
    <template slot-scope="props" >
      <el-form label-position="left" inline class="demo-table-expand" >
        <el-form-item label="文章标题">
          <span>{{ props.row.articleTitle }}</span>
        </el-form-item>
        <el-form-item label="文章简介">
          <span>{{ props.row.articleAbstract }}</span>
        </el-form-item>
        <el-form-item label="文章作者">
          <span>{{ props.row.articleAuthor }}</span>
        </el-form-item>
        <el-form-item label="发表时间">
          <span>{{ props.row.articleTime }}</span>
        </el-form-item>
        <el-form-item label="文章分类">
          <span>{{ props.row.articleCategory }}</span>
        </el-form-item>
        <el-form-item label="文章浏览次数">
          <span>{{ props.row.articleViewCount }}</span>
        </el-form-item>
        <el-form-item label="文章是否上架">
          <span v-if="props.row.articleShelvesOr == 0">上架</span>
          <span v-if="props.row.articleShelvesOr == 1">下架</span>
        </el-form-item>
      </el-form>
    </template>
  </el-table-column>
  <el-table-column
      label="文章标题"
      prop="articleTitle">
  </el-table-column>
  <el-table-column
      label="文章简介"
      prop="articleAbstract">
  </el-table-column>
  <el-table-column
      label="作者"
      prop="articleAuthor">
  </el-table-column>
    <el-table-column
        fixed="right"
        label="操作"
        width="150">
      <template slot-scope="scope">
        <el-button @click="handleClick(scope.row.articleId)" type="text" size="small">查看</el-button>
        <el-button @click="editorArticle(scope.row.articleId)" type="text" size="small">编辑</el-button>
        <el-button size="small" type="text" @click="deleteArticle(scope.row.articleId)">删除</el-button>
      </template>
    </el-table-column>
</el-table>
  <div class="block">
    <el-pagination
        background
        @current-change="handleCurrentChange"
        layout="prev, pager, next"
        :current-page="currentPage"
        :page-sizes="pageSizes"
        :page-size="PageSize"
        :total="totalCount">
    </el-pagination>
  </div>

</div>
</template>

<script>
export default {
  name: "ArticleStatistical",
  data() {
    return {
      tableData: [],
      // 默认显示第几页
      currentPage:1,
      // 总条数，根据接口获取数据长度(注意：这里不能为空)
      totalCount:'',
      // 个数选择器（可修改）
      pageSizes:[1,2,3,4],
      // 默认每页显示的条数（可修改）
      PageSize:10,
    }
  },
  methods: {
    handleClick(row) {
      let routeUrl =  this.$router.resolve({
        path: '/article',
        query: {
          id:row
        }
      })
      window.open(routeUrl.href, '_blank');
    },
    handleCurrentChange(val) {
      // 改变默认的页数
      this.currentPage=val
      // 切换页码时，要获取每页显示的条数
      this.fetchData((val-1)*(this.PageSize),this.PageSize)
    },
    fetchData(page,limit){
      const _this = this
      axios.get('https://www.realmmy.com/show/',{
        params:{
          limit:limit,
          page:page
        }}
      ).then(function (resp) {
        _this.tableData = resp.data
      });
    },
    deleteArticle(articleId) {
      this.$confirm('此操作将删除该文章, 是否继续?', '警告', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const _this=this;
        axios.get('https://www.realmmy.com/manage/deleteArticle?articleId='+articleId,).then(function (resp) {
          _this.$message({
            type: 'success',
            message: '删除成功!'
          });
          _this.handleCurrentChange(_this.currentPage)
        }).catch(error =>{
          _this.$message({
              type: 'warning',
              message: '删除失败请重试!'
                });
            })
      })
    },
    editorArticle(id){
      this.$router.push({
        path: '/theArticleEditor',
        query: {
          id:id
        }
      })
    },
    openFun(row , expandedRows){
      if(expandedRows.length==1){
        expandedRows.length=0
        // alert(1)
      }
    }
  },
  created() {
    const _this = this
    axios.get('https://www.realmmy.com/api/show/', {params:{
          limit:10,
          page:0
    }}).then(function (resp) {
      _this.tableData = resp.data
    }),
    axios.get('https://www.realmmy.com/api/show/articleCount',).then(function (resp) {
          _this.totalCount = resp.data
    });

  }
}
</script>

<style scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
