<template>
<div class="categoryStatistical">
  <el-button  type="text" @click="open()">添加标签</el-button>
  <el-table
      :data="tableData"
      style="width: 100%"
      :default-sort = "{prop: 'date', order: 'descending'}"
  >
    <el-table-column
        prop="categoryId"
        label="序号"
        sortable
        width="180">

    </el-table-column>
    <el-table-column
        prop="theHeat"
        label="热度"
        sortable
        width="180">
    </el-table-column>
    <el-table-column
        prop="categoryName"
        label="地址"
        :formatter="formatter">
    </el-table-column>
    <el-table-column
        fixed="right"
        label="标签"
        width="150">
      <template slot-scope="scope">
        <el-button @click="editorArticle(scope.row.categoryId,scope.row.categoryName)" type="text" size="small">编辑</el-button>
        <el-button type="text" size="small">删除</el-button>
      </template>
    </el-table-column>
</el-table>
<!--  <div class="block">-->
<!--    <el-pagination-->
<!--        layout="prev, pager, next"-->
<!--        :total="50"-->
<!--        :page-size="20"-->
<!--        :pager-count="11"-->
<!--    >-->
<!--    </el-pagination>-->
<!--  </div>-->

</div>
</template>

<script>
export default {
  name: "categoryStatistical",
  data() {
    return {
      tableData: []
    }
  },
  methods: {
    handleClick(row) {
      let routeUrl =  this.$router.resolve({
        path: '/article',
        query: {
          id:row
        }
      })
      window.open(routeUrl.href, '_blank');
    },
    open() {
      this.$prompt('请输入标签', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputPattern:/\S/,
        inputErrorMessage: '标签不能为空',
      }).then(({ value }) => {
       7020
        let data={'categoryName':value}
        axios.post('https://www.realmmy.com/api/manage/categoryAdd?categoryName='+value,).then(function (resp) {
          _this.$message({
            type: 'success',
            message: '新添加标签: ' + value
          });
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    editorArticle(id,name){
      this.$prompt('请输入标签', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        // inputPattern: /[\w!#$%&'*+/=?^_`{|}~-]+(?:\.[\w!#$%&'*+/=?^_`{|}~-]+)*@(?:[\w](?:[\w-]*[\w])?\.)+[\w](?:[\w-]*[\w])?/,
        inputPattern:/\S/,
        inputErrorMessage: '标签不能为空',
        inputValue:name
      }).then(({ value }) => {
        7020
        let data={'categoryName':value}
        axios.post('https://www.realmmy.com/api/manage/categoryUpdate?categoryName='+value+'&categoryId='+id,).then(function (resp) {
          _this.$message({
            type: 'success',
            message: '更改标签: ' + value
          });
          axios.post('https://www.realmmy.com/api/manage/category').then(function (resp) {
            _this.tableData = resp.data
          })
        })
      }).catch(() => {
        this.$message({
          type: 'info',
          message: '取消输入'
        });
      });
    },
    openFun(row , expandedRows){
      if(expandedRows.length==1){
        expandedRows.length=0
        // alert(1)
      }
    }
  },
  created() {
    const _this = this
    axios.post('https://www.realmmy.com/api/manage/category').then(function (resp) {
      _this.tableData = resp.data
    })
  }
}
</script>

<style scoped>
.demo-table-expand {
  font-size: 0;
}
.demo-table-expand label {
  width: 90px;
  color: #99a9bf;
}
.demo-table-expand .el-form-item {
  margin-right: 0;
  margin-bottom: 0;
  width: 50%;
}
</style>
