<template>

  <div class="HomePage" style="height: 100%" v-title data-title="个人技术思想">
    <v--hard></v--hard>
    <div style="width: 75%; margin: 30px auto;height:500px">
      <div style="width:60%; float: left">
        <el-carousel indicator-position="outside" :interval="5000" height="500px" arrow="always">
          <el-carousel-item v-for="items in url" :key="items.index">
            <el-image style="width: 100%;height: 100%;" :src="items.src" fit="fill"></el-image>
          </el-carousel-item>
        </el-carousel>
      </div>
      <div style="width: 35%;float: right">
        <div style="width: 100%;background-color: #42b983;height: 240px"><img :src="zuo" style="height:240px;width: 100%"></div>
        <div style="width: 100%;background-color: red;height: 240px;margin-top: 20px"><img :src="you" style="height: 240px;width: 100%">
        </div>

      </div>

    </div>

    <div style="width: 75%;margin: 0 auto;padding-top: 40px;height: 1000px">
      <div style="width: 70%;float: left">
        <v-article></v-article>
      </div>
      <div style="width: 25%;height: 1000px;float: right;margin-top: 30px">
        <v-category></v-category>
        <div style="padding-top: 20px;height: 500px">
          <el-card class="box-card">
            <div slot="header" class="clearfix">
              <span>联系方式</span>
            </div>
            <div>
              <div style="padding-left: 30px">
                <el-link style="padding-top: 30px" type="success">联系我们</el-link>
                <br>
                <el-link style="padding-top: 30px" type="primary">www.realmmy.com</el-link>
                <br>
                <el-link style="padding-top: 30px" type="warning">QQ:756317801</el-link>
                <br>
              </div>
            </div>
          </el-card>
        </div>
<!--        <div style="padding-top: 20px;height: 500px">-->
<!--          <el-card class="box-card">-->
<!--            <div slot="header" class="clearfix">-->
<!--              <span>卡片名称</span>-->
<!--            </div>-->
<!--            <div>-->
<!--              <div style="padding-left: 30px">-->
<!--                <el-link style="padding-top: 10px" type="success">联系我们</el-link>-->
<!--                <br>-->
<!--                <el-link style="padding-top: 10px" type="primary">www.realmmy.com</el-link>-->
<!--                <br>-->
<!--                <el-link style="padding-top: 10px" type="warning">QQ:756317801</el-link>-->
<!--                <br>-->
<!--              </div>-->
<!--            </div>-->
<!--          </el-card>-->
<!--        </div>-->
      </div>
    </div>

    <v-Foot></v-Foot>


  </div>
</template>

<script>
import About from "@/components/About";
import Image from "@/views/Image";
import Hard from "@/components/Hard";
import Category from "@/components/category/category";
import Article from '@/components/article/Article';
import Foot from "@/components/foot/Foot";

export default {
  name: "HomePage",
  components: {
    Image,
    'v-Image': Image,
    'v-About': About,
    'v-Hard': Hard,
    'v-category': Category,
    'v-article': Article,
    'v-Foot': Foot
  },
  data() {
    return {
      url: [{src: require('../assets/img/a.jpg')}, {src: require('../assets/img/c.jpg')}],
      fit: 'fill',
      value: new Date(),
      zuo: require('../assets/img/a.jpg'),
      you: require('../assets/img/c.jpg')
    };
  },
  methods: {},
  created() {
  }
}
</script>

<style scoped>

.BTi {
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  font-size: 20px;
  font-weight: bold;
}

</style>
