<template>
<div class="TheArticleAdd">
  <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
    <input type="hidden" v-model="ruleForm.articleId">
    <el-form-item label="文章名称" prop="articleTitle">
      <el-input v-model="ruleForm.articleTitle"></el-input>
    </el-form-item>
    <el-form-item label="文章标题" prop="articleAbstract">
      <el-input v-model="ruleForm.articleAbstract"></el-input>
    </el-form-item>
    <el-form-item label="文章作者">
      <el-input v-model="ruleForm.articleAuthor"></el-input>
    </el-form-item>
    <el-form-item label="分类" prop="articleCategory">
      <el-select  v-model="ruleForm.articleCategory" placeholder="请选择活动区域">
        <el-option v-for="categorys in category" :key="categorys.categoryId" :label="categorys.categoryName" :value="categorys.categoryId"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="是否上架" prop="delivery">
      <el-radio-group v-model="ruleForm.articleShelvesOr" size="medium">
        <el-radio border :label="0">上架</el-radio>
        <el-radio border label="1">下架</el-radio>
      </el-radio-group>
    </el-form-item>
    <el-form-item label="内容" prop="articleText">
      <v--article-text style="height: 100%"  v-model="ruleForm.articleText" ></v--article-text>
    </el-form-item>
    <el-form-item style="margin-top: 60px">
      <el-button type="primary" @click="submitForm('ruleForm')">确认修改</el-button>
    </el-form-item>
  </el-form>
</div>
</template>

<script>
import ArticleText from '@/components/ArticleText'
export default {
    name: "TheArticleAdd",
    components:{
      'v-ArticleText':ArticleText
      },
  contxt: {
    type: String,
    default: ''
  },
  data() {
    return {
      ruleForm: {
        articleId:'',
        articleTitle: '',
        articleAbstract: '',
        articleText: '',
        articleAuthor: '',
        articleCategory: '',
        articleShelvesOr: 0
      },
      category:[],
      rules: {
        articleTitle: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
          { min: 1, max: 50, message: '长度在 1 到 20 个字符', trigger: 'blur' }
        ],
        articleCategory: [
          { required: true, message: '请选择文章状态', trigger: 'change' }
        ],
        articleAbstract:[
          { required: false, message: '请选择文章状态', trigger: 'change' }
        ],
        articleText: [
          { required: true, message: '请填写文章内容', trigger: 'blur' }
        ]
      }
    };
  },
  methods: {
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          axios.post('http://47.103.147.94:7020/manage/articleUpdate',this.ruleForm).then(function (resp) {
            if(resp.data==200){
              alert("修改成功")
            }
           alert()
          window.open("/articleStatistical")
          })
        } else {
          console.log('error submit!!');
          alert("修改失败，请稍后重试")
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    }
  },
  created() {
    const _this = this
    let id=this.$route.query.id
    axios.post('http://47.103.147.94:7020/manage/category').then(function (resp) {
      _this.category = resp.data
    })
    axios.post('http://47.103.147.94:7020/article?id='+id).then(function (resp) {
      _this.ruleForm = resp.data
    })

  }

}
</script>

<style scoped>

</style>
